<template>
      <div class="pageParametersCreate">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'pageParameters', params: {} }">
          Page Parameters
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'pageParametersCreate', params: {} }">
            Create Page Parameters
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Create Page Parameters</v-card-title>
          <v-card-text>
            <pageParameters-form></pageParameters-form>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <script>
    import PageParametersForm from './PageParametersForm.vue';

    export default {
      name: 'pageParameters',
      components: {
        PageParametersForm
      },
      created() {
        this.$emit("showParent", false);
      }
    }
    </script>
    